import { Link } from 'gatsby'
import React from 'react'

export default () => (
  <header>
    <div className="container">
      <Link to="/">
        <div className="logo">
          <span className="small">T</span>H<span className="small">L</span>
        </div>
      </Link>
    </div>
  </header>
)
