import { graphql, Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import React from 'react'
import BlogMeta from '../components/BlogMeta'
import Layout from '../components/layouts'
import Header from '../components/layouts/Header'
import { ImageCaption, Quote, Text } from '../components/slices'

// Query for the Blog Post content in Prismic
export const query = graphql`
  query BlogPostQuery($uid: String) {
    prismicPost(uid: { eq: $uid }) {
      id
      uid
      lang
      type
      tags
      data {
        date
        title {
          raw
        }
        author
        body {
          ... on PrismicPostDataBodyText {
            slice_label
            slice_type
            primary {
              text {
                raw
              }
            }
          }
          ... on PrismicPostDataBodyQuote {
            slice_label
            slice_type
            primary {
              quote {
                raw
              }
            }
          }
          ... on PrismicPostDataBodyImageWithCaption {
            id
            slice_label
            slice_type
            primary {
              image {
                alt
                url
              }
              caption {
                raw
              }
            }
          }
        }
      }
    }
  }
`

// Sort and display the different slice options
const PostSlices = ({ slices }) =>
  slices.map((slice, index) => {
    const res = (() => {
      switch (slice.slice_type) {
        case 'text':
          return (
            <div key={index} className="homepage-slice-wrapper">
              <Text slice={slice} />
            </div>
          )

        case 'quote':
          return (
            <div key={index} className="homepage-slice-wrapper">
              <Quote slice={slice} />
            </div>
          )

        case 'image_with_caption':
          return (
            <div key={index} className="homepage-slice-wrapper">
              <ImageCaption slice={slice} />
            </div>
          )

        default:
      }
    })()
    return res
  })

// Display the title, date, and content of the Post
const PostBody = ({ blogPost, tags }) => (
  <div>
    <Header />
    <div className="container post-header">
      <div className="back">
        <Link to="/">back to list</Link>
      </div>
      <h1>
        {RichText.asText(blogPost.title.raw).length !== 0
          ? RichText.asText(blogPost.title.raw)
          : 'Untitled'}
      </h1>
      <BlogMeta post={blogPost} tags={tags} />
    </div>
    {/* Go through the slices of the post and render the appropiate one */}
    <PostSlices slices={blogPost.body} />
  </div>
)

export const Post = ({ data }) => {
  if (!data) return null
  // Define the Post content returned from Prismic
  const post = data.prismicPost.data

  return (
    <Layout>
      <PostBody blogPost={post} tags={data.prismicPost.tags} />
    </Layout>
  )
}

export default Post
